import AdditionalInfo from "src/components/Complex/Containers/AdditionalInfo";
import ApprovePlannedInvoicesModalWithForm from "src/components/Complex/Modals/ApprovePlannedInvoicesModalWithForm";
import ButtonBack from "../../Basic/Simple/Buttons/ButtonBack";
import ButtonClear from "../../Basic/Simple/Buttons/ButtonClear";
import ButtonDeleteWithYesNoDialog from "../../Basic/Simple/Buttons/ButtonDeleteWithYesNoDialog";
import ButtonGroup from "../../Basic/Simple/Buttons/ButtonGroup";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import ButtonUpdate from "../../Basic/Simple/Buttons/ButtonUpdate";
import DialogContentWrapper from "src/components/Basic/Simple/Dialogs/DialogContentWrapper";
import NotFound from "src/components/Screens/NotFound/NotFound";
import Page from "src/components/Basic/Mixed/Pages/Page";
import PlannedInvoiceContainer from "./PlannedInvoiceContainer/PlannedInvoiceContainer";
import PlannedInvoiceRowsTable from "./PlannedInvoiceRows/PlannedInvoiceRowsTable";
import SectionContainer from "../../Basic/Mixed/Sections/SectionContainer";
import SectionFloatingForButtons from "src/components/Basic/Simple/Sections/SectionFloatingForButtons";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import isDisableSubmit from "src/utils/isDisableSubmit";
import subcontractApi from "src/redux/services/SubcontractService";
import useFormGlue from "src/hooks/useFormGlue";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useReload from "src/hooks/useReload";
import useTranslation from "src/hooks/useTranslationWrapper";
import { AdditionalInfoData } from "src/components/Complex/Containers/AdditionalInfoGetStructure";
import { PlannedInvoiceExtended } from "src/components/Screens/PlannedInvoiceBase/PlannedInvoiceContainer/PlannedInvoiceContainerStructure";
import { PlannedInvoiceRow } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { escape } from "src/utils/translate";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useGetInvoiceplanQuery } from "src/redux/services/InvoiceplanService";
import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  useDeletePlannedInvoiceMutation,
  useGetPlannedInvoiceBillingBaseQuery,
  useGetPlannedInvoiceQuery,
  usePatchPlannedInvoiceMutation,
} from "src/redux/services/PlannedinvoiceService";

export const PlannedInvoiceBase = () => {
  const { contractId, id } = useParams<{ contractId: string; id: string }>();
  const { reloadKey, reload } = useReload();

  const [openModal, setOpenModal] = useState(false);
  const [t] = useTranslation();

  const {
    data: contract,
    isLoading: isLoadingContract,
    isError: isContractError,
  } = useGetContractQuery(contractId, { skip: !contractId });

  const {
    data: plannedInvoice,
    isLoading: isLoadingPlannedInvoice,
    isError: isPlannedInvoiceError,
  } = useGetPlannedInvoiceQuery(id, { skip: !id });

  const { data: invoicePlan, isLoading: isLoadingInvoicePlan } =
    useGetInvoiceplanQuery(plannedInvoice?.invoicePlanId, {
      skip: !plannedInvoice?.invoicePlanId,
    });
  const subContractFilter = {
    _id: plannedInvoice?.subcontractIds,
  };
  const { data: subcontractsQueryResult, isLoading: isLoadingSubcontract } =
    subcontractApi.useGetSubcontractsQuery(subContractFilter, {
      skip: !plannedInvoice?.subcontractIds,
    });
  const subcontracts = subcontractsQueryResult?.data || [];

  const isMilestone = invoicePlan?.type === "milestone" || false;

  const isRunningRate =
    invoicePlan?.type === "runningrate" ||
    subcontracts?.some((sc) => sc.type === "runningrate");

  const { data: runningrateData, isLoading: isLoadingRunningrateData } =
    useGetPlannedInvoiceBillingBaseQuery(id, {
      skip: !isRunningRate,
    });

  const [patchPlannedInvoice] = usePatchPlannedInvoiceMutation();
  const [deletePlannedInvoice] = useDeletePlannedInvoiceMutation();

  const formGlueAdditionalInfo = useFormGlue<AdditionalInfoData>();
  const formGlueContainer = useFormGlue<PlannedInvoiceExtended>();
  const formGlueTable = useFormGlue<PlannedInvoiceRow[]>();

  const submitData = {
    ...formGlueAdditionalInfo.submitData,
    ...formGlueContainer.submitData,
    invoiceRows: formGlueTable.submitData,
  };

  const initialSubmitData = {
    ...formGlueAdditionalInfo.submitDataInitial,
    invoiceRows: formGlueTable.submitDataInitial,
  };

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        delete: deletePlannedInvoice,
        update: patchPlannedInvoice,
      },
      data: {
        update: submitData,
        delete: [id],
      },
      dataId: id,
      name: "Planned invoice",
      rerouteUrlOnSuccess: {
        delete: `/app/contracts/${contractId}/invoices/planned`,
      },
    },
    onSuccess: () => {
      formGlueAdditionalInfo.setHasTriedToSubmit(false);
      formGlueContainer.setHasTriedToSubmit(false);
      formGlueTable.setHasTriedToSubmit(false);
    },
  });

  const breadcrumbs = contractId
    ? [
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contractId}`,
        },
        {
          label: "Invoices",
          link: `/app/contracts/${contractId}/invoices/planned`,
        },
        {
          label: `Planned invoice${escape(` ${plannedInvoice?.number || ""}`)}`,
        },
      ]
    : [
        { label: "Operations" },
        { label: "Invoices", link: "/app/operations/invoices/planned" },
        {
          label: `Planned invoice${escape(
            ` ${plannedInvoice?.number || ""} `,
          )} on contract${escape(
            ` ${plannedInvoice?.projectExternalId || ""}`,
          )}`,
        },
      ];

  const submitDisabled = isDisableSubmit({
    data: submitData,
    initialData: initialSubmitData,
    isValid: true,
  });

  const Approve = (
    <ButtonPrimary
      onClick={async () => setOpenModal(true)}
      permissions={plannedInvoice?.permissions}
      permissionType={"exportRights"}
      disabled={plannedInvoice?.approved || !submitDisabled}
    >
      {plannedInvoice?.approved ? t("Approved") : t("Approve")}
    </ButtonPrimary>
  );

  if (isContractError || isPlannedInvoiceError) {
    return <NotFound type="planned_invoice_list" />;
  }

  return (
    <>
      <form
        ref={refForm}
        onSubmit={async (e) => {
          e.preventDefault();
          await onFormSubmit({ action: "update" });
        }}
      >
        <Page
          isLoading={
            isLoadingPlannedInvoice ||
            isLoadingInvoicePlan ||
            isLoadingSubcontract ||
            isLoadingContract ||
            (isRunningRate && isLoadingRunningrateData)
          }
          label={"Edit planned invoice"}
          breadcrumbs={breadcrumbs}
          boxRight={Approve}
          headingSx={contractId ? { paddingTop: "108px" } : {}}
        >
          <SectionContainer>
            <PlannedInvoiceContainer
              contract={contract!}
              plannedInvoice={plannedInvoice!}
              runningRateData={runningrateData || []}
              formGlue={formGlueContainer}
              key={"container" + reloadKey}
            />
            <AdditionalInfo
              formGlue={formGlueAdditionalInfo}
              invoicePlanInit={
                {
                  remarks: plannedInvoice?.remarks,
                  _id: plannedInvoice?._id,
                } as AdditionalInfoData
              }
              type="invoice"
              useExpand
            />
            <PlannedInvoiceRowsTable
              plannedInvoice={plannedInvoice!}
              formGlue={formGlueTable}
              key={"table" + reloadKey}
            />
          </SectionContainer>

          {/* Floating Button Wrapper as a Footer*/}
          <SectionFloatingForButtons isFullWidth>
            <SectionWithButtonContainer sx={{ margin: 0 }}>
              <ButtonBack />
              <ButtonGroup>
                {Approve}
                <ButtonClear onClick={reload} />
                <ButtonDeleteWithYesNoDialog
                  dialogContent={
                    <DialogContentWrapper
                      contentType="alert"
                      title="Are you sure you want to delete the planned invoice?"
                      message={
                        isMilestone
                          ? "This action will also delete corresponding milestone on the invoice plan."
                          : ""
                      }
                    />
                  }
                  disabled={!!plannedInvoice?.exported}
                  onSubmit={() => {
                    onFormSubmit({ action: "delete" }).catch((e) =>
                      console.error(e),
                    );
                  }}
                />
                <ButtonUpdate
                  initialSubmitData={initialSubmitData}
                  isSubmitting={isSubmitting}
                  isValid={
                    formGlueContainer.isFormValid &&
                    formGlueTable.isFormValid &&
                    !plannedInvoice?.exported
                  }
                  onSubmit={() => {
                    refForm.current.requestSubmit();
                    formGlueAdditionalInfo.setHasTriedToSubmit(true);
                    formGlueContainer.setHasTriedToSubmit(true);
                    formGlueTable.setHasTriedToSubmit(true);
                  }}
                  permissions={plannedInvoice?.permissions}
                  submitData={submitData}
                />
              </ButtonGroup>
            </SectionWithButtonContainer>
          </SectionFloatingForButtons>
        </Page>
      </form>
      <ApprovePlannedInvoicesModalWithForm
        plannedInvoice={plannedInvoice}
        openModal={openModal}
        handleCloseModal={() => setOpenModal(false)}
      />
    </>
  );
};

export default PlannedInvoiceBase;
