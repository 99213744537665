/**
 *Created by Mikael Lindahl on 2022-10-11
 */

import { ArticleWithPermissions } from "src/accurasee-backend-types/app/article/article.types";
import { ContractStatus } from "src/accurasee-backend-types/app/contracts/contract.types";
import { Currency } from "src/accurasee-backend-types/app/currency/currency.types";
import {
  Customer,
  CustomerType,
} from "src/accurasee-backend-types/app/customer/customer.types";
import { EmailTemplate } from "src/accurasee-backend-types/app/emailtemplates/emailtemplates.types";
import { Language } from "src/accurasee-backend-types/app/language/language.types";
import { Project } from "src/accurasee-backend-types/app/project/project.types";
import { Namespaces } from "src/accurasee-backend-types/app/translation/translation.types";
import {
  InvoicePlanType,
  Offset,
} from "../accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { EditRights } from "../accurasee-backend-types/app/general/editrights.types";
import {
  CriticalLevel,
  Warning,
  WarningType,
} from "src/accurasee-backend-types/app/warning/warning.types";

const Constants: {
  COMPANY_CASHFLOW_DATE_RANGE: number;
  CONTRACT_STATUS_FILTER_OPTIONS: string[];
  AXIS_TICK_LIMIT: number;
  ACCOUNTING_SETTINGS: string[];
  ALLOWED_CONTRACT_ARTICLE_KEYS: string[];
  AUTH_RIGHT_CONTROLLED_BY_CONTRACT: string[];
  DRAWER_WIDTH: number;
  DATE_FORMAT: string;
  DATE_FORMAT_SHORT: string;
  DEBOUNCE: {
    TEXT_INPUT: number;
    SUBMIT_BUTTON: number;
  };
  DEFAULT_CURRENCIES: Currency[];
  DEFAULT_LANGUAGES: Language[];
  DEFAULT_CREATE_PROJECT: Partial<Project>;
  DEFAULT_OFFER_DEADLINE: number;
  DEFAULT_PLACEHOLDER_FOR_SEARCH_BOX: string;
  DEFAULT_PERMISSIONS: Partial<EditRights["permissions"]>;
  DEFAULT_REMINDER_TIME: string;
  DEFAULT_REMINDER_TIMEZONE: string;
  DEFAULT_TRANSLATION_NAMESPACE: Namespaces;
  ERROR_TEXTS: {
    INACTIVE_INDEX: string;
  };
  ESTIMATE_INDICATOR: string;
  FILTER_DATE_FIELDS: string[];
  FILTER_NUMBER_FIELDS: string[];
  MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE: number;
  MAX_CUSTOM_FIELDS: number;
  NOTIFICATION_LIMIT: number;
  INITIAL_ARTICLE_DATA: Partial<ArticleWithPermissions>;
  INITIAL_CUSTOMER_DATA: Partial<Customer>;
  INITIAL_EMAIL_TEMPLATE_DATA: Partial<EmailTemplate>;
  INITIAL_WARNING_DATA: Partial<Warning>;
  OFFSET_OPTIONS: Offset["format"][];
  OPTIONS_INVOICE_TYPE: {
    label: string;
    value: InvoicePlanType;
  }[];
  LOCKED_URL: string[];
  REDIRECT_URL: string[];
  REOPENABLE_CONTRACT_STATUSES: Partial<ContractStatus>[];
  RETAINED_FUNDS_ROW_RATIO: number;
  RETAINED_FUNDS_TOTAL_RATIO: number;
  TABLE_ITEMS_MIN_WIDTH: {
    ITEM_DESCRIPTION: string;
    PRICE: string;
    ADJUSTMENT: string;
  };
  COMPLETION_CONTRACT_STATUSES: Partial<ContractStatus>[];
  TRANSLATION_ESCAPE_START: string;
  TRANSLATION_ESCAPE_END: string;
} = {
  COMPANY_CASHFLOW_DATE_RANGE: 24, //months
  CONTRACT_STATUS_FILTER_OPTIONS: [
    "Upcoming",
    "Ongoing",
    "Finished",
    "Locked",
    "Archived",
    "Invoiced",
    "Partially invoiced",
    "Cancelled",
  ],
  AXIS_TICK_LIMIT: 5, // For Graph - rotated if number of items on Axis is greater
  ACCOUNTING_SETTINGS: ["incomes", "labourCost", "supplierCost"],
  ALLOWED_CONTRACT_ARTICLE_KEYS: [
    "quantity",
    "quantityMin",
    "isQuantityEstimate",
    "price",
    "priceMin",
    "isPricePerUnitEstimate",
    "isIndex",
    "defaultArticle",
    "quantityMinValue",
    "articleId",
  ],
  AUTH_RIGHT_CONTROLLED_BY_CONTRACT: [
    "budgets",
    "costs",
    "customer_invoices",
    "incomes",
    "invoiceplans",
    "operationalresources",
    "planned_invoices",
    "subcontracts",
    "supplier_invoices",
  ],
  DRAWER_WIDTH: 240,
  DATE_FORMAT: "YYYY-MM-DDThh:mm:ss.SSSz",
  DATE_FORMAT_SHORT: "YYYY-MM-DD",
  DEBOUNCE: {
    // Seem like this combo makes it hard to submit data that has not get been added to form due to text input
    // being debounced. If problems occurs, try to increase the value of SUBMIT_BUTTON debounce. However,
    // this will make the submit button less responsive.
    TEXT_INPUT: 1000,
    SUBMIT_BUTTON: 500,
  },
  DEFAULT_CURRENCIES: [
    {
      code: "SEK",
      description: "Svensk krona",
    },
  ] as Currency[],
  DEFAULT_LANGUAGES: [
    {
      code: "en",
      nativeName: "English",
      name: "English",
      isActive: true,
    },
    {
      code: "sv",
      nativeName: "Swedish",
      name: "Swedish",
      isActive: true,
    },
    {
      code: "vi",
      nativeName: "Tiếng Việt",
      name: "Tiếng Việt",
      isActive: true,
    },
  ] as Language[],
  DEFAULT_CREATE_PROJECT: {
    externalId: "0",
    name: "Create project",
    number: "0",
    _id: "default_create_project",
  } as unknown as Partial<Project>,
  DEFAULT_OFFER_DEADLINE: 14, // default 14 days forward from today
  DEFAULT_PLACEHOLDER_FOR_SEARCH_BOX: "Search",
  DEFAULT_PERMISSIONS: {
    deleteRights: true,
    exportRights: false,
    readRights: false,
    updateRights: true,
    writeRights: true,
  } as Partial<EditRights["permissions"]>,
  DEFAULT_REMINDER_TIME: "06:00",
  DEFAULT_REMINDER_TIMEZONE: "Europe/Stockholm",
  DEFAULT_TRANSLATION_NAMESPACE: "common" as Namespaces,
  ERROR_TEXTS: {
    INACTIVE_INDEX: "Inactive index. Please choose another",
  },
  ESTIMATE_INDICATOR: "~ ",
  FILTER_DATE_FIELDS: [
    "deadline",
    "dueDate",
    "invoiceDate",
    "startDate",
    "endDate",
  ],
  FILTER_NUMBER_FIELDS: [
    "offerPrice",
    "totalExcludingVAT",
    "total",
    "scheduledCurrentYear",
    "invoicedCurrentYear",
    "totalScheduledCurrentYear",
    "totalInvoicedCurrentYear",
  ],
  MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE: 45,
  MAX_CUSTOM_FIELDS: 4,
  NOTIFICATION_LIMIT: 10,
  INITIAL_ARTICLE_DATA: {
    vat: "0.25",
    salesPrice: 0,
    type: "STOCK",
    active: true,
    houseWork: false,
  } as unknown as Partial<ArticleWithPermissions>,
  INITIAL_CUSTOMER_DATA: {
    customerType: CustomerType["Company"],
    reversedConstructionVAT: false,
    termsOfPaymentId: "63e4ec2d246b0fbbc85a93bb", // 30 days
  } as unknown as Partial<Customer>,
  INITIAL_EMAIL_TEMPLATE_DATA: {
    description: "",
  } as unknown as Partial<EmailTemplate>,
  INITIAL_WARNING_DATA: {
    issueType: WarningType.ProductionIssue,
    criticalLevel: CriticalLevel.Low,
    active: false,
  } as unknown as Partial<Warning>,
  // URL that has a following id in url
  LOCKED_URL: [
    "/invoiceplans",
    "/invoices/planned",
    "/invoices/customer",
    "/subcontracts",
  ],
  OFFSET_OPTIONS: [
    {
      value: "MMMM",
      label: "January, February",
    },
    {
      value: "MMM",
      label: "Jan, Feb",
    },
    {
      value: "Qo",
      label: "1st..4th (quarter)",
    },
    {
      value: "QQQ",
      label: "Q1, Q2 (quarter)",
    },
    {
      value: "yyyy-MM-dd",
      label: "2022-02-11",
    },
    {
      value: "w",
      label: "1..53 (week)",
    },
    {
      value: "yyyy",
      label: "2023, 2024",
    },
    {
      value: "yyy",
      label: "23, 24",
    },
  ] as Offset["format"][],
  // To be included in the future
  OPTIONS_INVOICE_TYPE: [
    {
      label: "Milestone",
      value: "milestone",
    },

    {
      label: "Milestone retained funds",
      value: "milestone_retained_funds",
    },
    {
      label: "Periodic",
      value: "periodic",
    },
    // {
    //   label: "Fixed price",
    //   value: "fixedprice",
    // },
    {
      label: "Running rate",
      value: "runningrate",
    },
    // {
    //   label: "Custom",
    //   value: "custom",
    // },
    {
      label: "Single invoice",
      value: "singleinvoice",
    },
  ],
  REDIRECT_URL: ["/invoiceplans", "/linkedinvoicedata", "/economy"],
  // Reopen means that resets status to “invoiced”, only possible if status is Locked/Finished/Archived
  REOPENABLE_CONTRACT_STATUSES: ["locked", "finished", "archived"],
  RETAINED_FUNDS_ROW_RATIO: 0.1,
  RETAINED_FUNDS_TOTAL_RATIO: 0.05,
  TABLE_ITEMS_MIN_WIDTH: {
    ITEM_DESCRIPTION: "250px",
    PRICE: "200px",
    ADJUSTMENT: "500px",
  },
  // For completion action statuses are allowed to change to
  COMPLETION_CONTRACT_STATUSES: ["locked", "finished", "archived", "invoiced"],
  TRANSLATION_ESCAPE_START: "##s",
  TRANSLATION_ESCAPE_END: "##e",
};

export default Constants;
