/**
 *Created by Mikael Lindahl on 2023-11-28
 */

import _c from "src/constants/Constants";
import {
  getCustomMinMaxFilterListOptions,
  getCustomMinMaxfilterOptions,
  getCustomMultiselectFilterOptions,
} from "src/components/Complex/Containers/Filterhelper";
import { ContractTypeWithPermissions } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { ContractWithPermissionsAndTotals } from "src/accurasee-backend-types/app/contracts/contract.types";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import { escape } from "src/utils/translate";
import { getYear } from "date-fns";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { Types } from "mongoose";
import { UserFormTableGetStructureOptional } from "src/hooks/useFormTable";
import Constants from "src/constants/Constants";
import getCustomerName from "src/utils/getCustomerName";
import getDisplayValueById from "src/utils/getDisplayValueById";
import getSelectOptions from "src/utils/getSelectOptions";
import getTableFilterOwnerIds from "src/utils/getTableFilterOwnerIds";

interface GetStructureExtraProps {
  contractTypes?: ContractTypeWithPermissions[];
  currencyCode?: string;
  customers?: Customer[];
  location: Location;
  idSummaries?: Types.ObjectId;
  users?: ReturnUser[];
}

export const getStructure: UserFormTableGetStructureOptional<
  Partial<ContractWithPermissionsAndTotals>,
  GetStructureExtraProps
> = (props) => {
  const t = props?.t ? props.t : (s: string) => s;

  return {
    items: [
      {
        dataName: "projectExternalId",
        headerLabel: "#",
        type: ({ data, rowIndex }) => {
          return data[rowIndex]?._id === props?.extraProps?.idSummaries
            ? "text"
            : "link";
        },
        sort: true,
        redirectLink: (_props) =>
          props?.extraProps?.location.pathname +
          "/" +
          String(_props.data[_props.rowIndex]._id),
      },
      // Remove for now
      // {
      //   type: "text",
      //   dataName: "projectName",
      //   headerLabel: "Project",
      //   alignColumnContent: "start",
      //   sort: true,
      //   showCellWhen: (_props) =>
      //     _props.data[_props.rowIndex]._id !== props?.extraProps?.idSummaries,
      //   getValue: ({ data, rowIndex }) => {
      //     const name = data[rowIndex]?.projectName || "";
      //     const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
      //     return name.length > maxLength
      //       ? name.slice(0, maxLength) + "..."
      //       : name;
      //   },
      // },
      {
        dataName: "name",
        headerLabel: "Name",
        type: "link",
        sort: true,
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== props?.extraProps?.idSummaries,
        redirectLink: (_props) =>
          props?.extraProps?.location.pathname +
          "/" +
          String(_props.data[_props.rowIndex]._id),
        getValue: ({ data, rowIndex }) => {
          const name = data[rowIndex]?.name || "";
          const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
          return name.length > maxLength
            ? name.slice(0, maxLength) + "..."
            : name;
        },
      },
      {
        type: "text",
        dataName: "ownerIds",
        headerLabel: "Contract owners",
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== props?.extraProps?.idSummaries,
        getValue: ({ data, rowIndex }) => {
          const owners =
            data[rowIndex]?.ownerIds?.map((ownerId) => {
              const owner = props?.extraProps?.users?.find(
                (e) => e._id === ownerId,
              );
              return `${owner?.firstName} ${owner?.lastName}`;
            }) || [];

          return owners?.join(", ") || " ";
        },
        ...getTableFilterOwnerIds({
          users: props?.extraProps?.users,
          dataLabel: t("Contract owners"),
        }),
      },
      {
        type: "badge",
        dataName: "status",
        headerLabel: "Status",
        sort: true,
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== props?.extraProps?.idSummaries,
        filter: true,
        filterOptions: {
          names: _c.CONTRACT_STATUS_FILTER_OPTIONS,
          renderValue: (value: string) => t(value),
        },
        customFilterListOptions: {
          render: (value: string) => t(value),
        },
        alignColumnContent: "center",
      },
      {
        type: "text",
        dataName: "contractTypeId",
        headerLabel: "Contract type",
        sort: true,
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== props?.extraProps?.idSummaries,
        alignColumnContent: "start",
        getValue: ({ data, rowIndex }) => {
          const result = props?.extraProps?.contractTypes?.find(
            (e) => e._id === data[rowIndex]?.contractTypeId,
          );
          return result?.name || "";
        },
        filter: true,
        filterType: "custom",
        filterOptions: getCustomMultiselectFilterOptions({
          dataLabel: t("Contract type"),
          options: getSelectOptions({
            data: props?.extraProps?.contractTypes,
            label: (c) => c.name,
            value: (c) => String(c._id),
          }),
          renderValue: (v: string) =>
            getDisplayValueById({
              idToMatch: v,
              items: props?.extraProps?.contractTypes || [],
            }),
        }),
        customFilterListOptions: {
          render: (values: string[]) =>
            values.map((v: string) =>
              getDisplayValueById({
                idToMatch: v,
                items: props?.extraProps?.contractTypes || [],
              }),
            ),
        },
      },
      {
        type: "text",
        dataName: "customerName",
        headerLabel: "Customer name",
        sort: true,
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== props?.extraProps?.idSummaries,
        alignColumnContent: "start",
        getValue: ({ data, rowIndex }) => {
          const customer = props?.extraProps?.customers?.find(
            (item) => item._id === data[rowIndex]?.customerId,
          );
          const name = getCustomerName(customer, {
            maxChars: Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE,
            excludeExternalId: true,
          });

          return name;
        },
      },
      {
        type: "date",
        dataName: "startDate",
        sort: true,
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== props?.extraProps?.idSummaries,
        headerLabel: "Start date",
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Start date"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(t("Start date"), "date"),
      },
      {
        type: "date",
        dataName: "endDate",
        sort: true,
        showCellWhen: (_props) =>
          _props.data[_props.rowIndex]._id !== props?.extraProps?.idSummaries,
        headerLabel: "End date",
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("End date"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(t("End date"), "date"),
      },
      {
        type: "text_currency",
        currencyCode: props?.extraProps?.currencyCode,
        dataName: "contractFeatures.totalPrice",
        headerLabel: "Total price",
        alignColumnContent: "end",
        getFinalFormatedValue: ({ data, rowIndex, value }) =>
          !!data[rowIndex]?.contractFeatures?.totalPrice ? value : "-",
      },
      {
        type: "text_currency",
        currencyCode: props?.extraProps?.currencyCode,
        dataName: "totalScheduled",
        sort: true,
        headerLabel: "Planned invoices",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        currencyCode: props?.extraProps?.currencyCode,
        dataName: "totalInvoiced",
        sort: true,
        headerLabel: "Invoiced",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        currencyCode: props?.extraProps?.currencyCode,
        dataName: "totalScheduledCurrentYear",
        sort: true,
        headerLabel: `Planned invoices${escape(
          " " + String(getYear(new Date())),
        )}`,
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Planned invoices") + ` ${getYear(new Date())}`,
        ),
        filterOptions: getCustomMinMaxfilterOptions(
          t("Planned invoices") + ` ${getYear(new Date())}`,
          "number",
        ),
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        currencyCode: props?.extraProps?.currencyCode,
        dataName: "totalInvoicedCurrentYear",
        sort: true,
        headerLabel: `Invoiced${escape(" " + String(getYear(new Date())))}`,
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Invoiced") + ` ${getYear(new Date())}`,
        ),
        filterOptions: getCustomMinMaxfilterOptions(
          t("Invoiced") + ` ${getYear(new Date())}`,
          "number",
        ),
        alignColumnContent: "end",
      },
    ],
  };
};

export const dataNames = getStructure().items.map((item) => item.dataName);
