import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonBack from "../../Basic/Simple/Buttons/ButtonBack";
import ButtonCancel from "../../Basic/Simple/Buttons/ButtonCancel";
import ButtonClear from "../../Basic/Simple/Buttons/ButtonClear";
import ButtonCreateOrUpdate from "../../Basic/Simple/Buttons/ButtonCreateOrUpdate";
import ButtonGroup from "../../Basic/Simple/Buttons/ButtonGroup";
import InvoiceplanTemplateTable from "src/components/Screens/InvoiceplanTemplate/InvoiceplanTemplateTable";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "../../Basic/Mixed/Sections/SectionContainer";
import SectionForTable from "src/components/Basic/Simple/Sections/SectionForTable";
import SectionWithButtonContainer from "../../Basic/Mixed/Sections/SectionWithButtonContainer";
import omit from "lodash/omit";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import { Types } from "mongoose";
import { useEffect, useMemo, useState } from "react";
import { useGetCompanyUsersQuery } from "src/redux/services/UserService";
import { useGetContractQuery } from "../../../redux/services/ContractService";
import { useGetInvoiceplanQuery } from "../../../redux/services/InvoiceplanService";
import { useParams } from "react-router-dom";
import { useAppSelector } from "src/redux/hooks/useAppSelector";
import {
  getStructure,
  GetStructureExtraProps,
  toSubmitData,
} from "./InvoiceplanTemplateStructure";
import {
  InvoicePlanTemplate as InvoicePlanTemplateType,
  InvoicePlanTemplateWithPermissions,
} from "src/accurasee-backend-types/app/invoiceplantemplate/invoiceplantemplate.types";
import {
  InvoicePlan,
  InvoicePlanRow,
} from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import {
  useCreateInvoiceplanTemplateMutation,
  useGetInvoiceplanTemplateQuery,
  useGetInvoiceplanTemplatesQuery,
  usePatchInvoiceplanTemplateMutation,
} from "src/redux/services/InvoicePlanTemplateService";

interface InvoicePlanTemplateProps {
  contractId?: string;
  createPath?: string;
  id?: Types.ObjectId;
  invoicePlanId?: string;
  onClose?: () => void;
}

const InvoicePlanTemplate = (props: InvoicePlanTemplateProps) => {
  const user = useAppSelector((state) => state.user.user)!;

  let { invoicePlanTemplateId } = useParams<{
    invoicePlanTemplateId?: string;
  }>();

  const { data: invoicePlanTemplate, isLoading: isLoadingInvoicePlanTemplate } =
    useGetInvoiceplanTemplateQuery(invoicePlanTemplateId, {
      skip: !invoicePlanTemplateId,
    });

  const {
    data: invoicePlanTemplatesQuery,
    isLoading: isLoadingInvoicePlanTemplates,
  } = useGetInvoiceplanTemplatesQuery(undefined);

  const { data: contract, isLoading: isLoadingContract } = useGetContractQuery(
    props?.contractId,
    { skip: !props?.contractId },
  );

  const { data: invoicePlan, isLoading: isLoadingInvoicePlan } =
    useGetInvoiceplanQuery(props?.invoicePlanId, {
      skip: !props?.invoicePlanId,
    });

  const invoicePlanTemplates = invoicePlanTemplatesQuery?.data;

  const { data: usersResponse, isLoading: isLoadingUsers } =
    useGetCompanyUsersQuery(undefined);
  const users = usersResponse?.data || [];

  const [patchInvoiceplanTemplate] = usePatchInvoiceplanTemplateMutation();
  const [createInvoicePlanTemplate] = useCreateInvoiceplanTemplateMutation();

  // Exclude the current name from the list of Template Names
  const templateNames = invoicePlanTemplates
    ?.map((invPT) => invPT.name.toLowerCase())
    .filter((name) => name !== invoicePlanTemplate?.name?.toLowerCase());

  let initialFormData = (
    invoicePlanTemplate
      ? {
          ...invoicePlanTemplate,
          ...(invoicePlanTemplate?.ownerIds?.length === 0 && user?._id
            ? { ownerIds: [user?._id] }
            : {}),
        }
      : {
          companyId: invoicePlan?.companyId!,
          invoiceRows: invoicePlan?.invoiceRows,
          invoicingPeriod: {
            allowExternalRowAdding: false,
            ...invoicePlan?.invoicingPeriod,
          },
          name: "",
          ownerIds: contract?.ownerIds!,
          reminder: invoicePlan?.reminder!,
          type: invoicePlan?.type,
        }
  ) as InvoicePlanTemplateWithPermissions;

  const [invoiceRows, setRows] = useState(initialFormData?.invoiceRows);
  const [isFormValidRows, setIsFormValidRows] = useState(true);

  const rowsSelected = useMemo(
    () =>
      invoiceRows?.map((row, index) =>
        initialFormData?.invoiceRows?.findIndex((el) => el._id === row._id),
      ),
    [invoiceRows, initialFormData?.invoiceRows],
  );

  useEffect(() => {
    setRows(initialFormData?.invoiceRows);
  }, [initialFormData?.invoiceRows]);

  // Default: all rows are selected
  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<InvoicePlanTemplateType, GetStructureExtraProps>({
    getStructure,
    extraProps: {
      templateNames,
      users,
      view: invoicePlanTemplateId ? "edit" : "create",
    },
    initialFormData,
  });

  const submitData = toSubmitData({
    data: { ...formData, invoiceRows } as InvoicePlanTemplateType,
    initData: initialFormData,
  });

  const initialSubmitData = toSubmitData({
    data: initialFormData as InvoicePlanTemplateType,
    initData: initialFormData,
  });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        create: createInvoicePlanTemplate,
        update: patchInvoiceplanTemplate,
      },
      data: {
        create: submitData,
        update: submitData,
      },
      dataId: invoicePlanTemplateId,
      name: "Invoice plan template",
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
      if (props.onClose) props.onClose();
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await onFormSubmit({
          action: invoicePlanTemplateId ? "update" : "create",
        }).catch((err) => console.log(err));
      }}
      ref={refForm}
    >
      <SectionContainer
        isLoading={
          isLoadingContract ||
          isLoadingInvoicePlan ||
          isLoadingInvoicePlanTemplate ||
          isLoadingInvoicePlanTemplates ||
          isLoadingUsers
        }
      >
        <Section key={"template-container"}>
          {BuildItems({
            items: structure.items,
            ...commonPropsBuildItem,
          })}
        </Section>
        {formData && formData.type !== "runningrate" ? (
          <SectionForTable
            key={"template-table"}
            label={
              formData?.type === "milestone" ? "milestones" : "invoice rows"
            }
            subLabel={"Row setting (optional)"}
          >
            <InvoiceplanTemplateTable
              data={(formData.invoiceRows as InvoicePlanRow[]) || []}
              invoicePlan={omit(formData, "ownerIds") as unknown as InvoicePlan}
              selectedRows={rowsSelected as number[]}
              setIsFormValidRows={setIsFormValidRows}
              setSelectedRows={setRows}
              view={invoicePlanTemplateId ? "edit" : "create"}
            />
          </SectionForTable>
        ) : (
          <></>
        )}
      </SectionContainer>
      <SectionWithButtonContainer>
        {!props.onClose && <ButtonBack />}
        <ButtonGroup>
          {props.onClose && (
            <ButtonCancel
              onClick={() => {
                setFormData(initialFormData);
                if (props.onClose) {
                  props.onClose();
                }
              }}
            />
          )}
          {!props.onClose && (
            <ButtonClear
              onClick={() => {
                setFormData(initialFormData);
              }}
            />
          )}
          <ButtonCreateOrUpdate
            isSubmitting={isSubmitting}
            initialSubmitData={invoicePlanTemplateId ? initialSubmitData : {}}
            isValid={isFormValid && isFormValidRows}
            label={invoicePlanTemplateId ? "update" : "create"}
            onSubmit={() => {
              refForm.current.requestSubmit();
              setHasTriedToSubmit(true);
            }}
            permissions={initialFormData?.permissions}
            submitData={submitData}
          />
        </ButtonGroup>
      </SectionWithButtonContainer>
    </form>
  );
};

export default InvoicePlanTemplate;
