import { Box, SxProps } from "@mui/material";
import { useEffect, useState } from "react";

interface SectionFloatingForButtonsProps {
  children: React.ReactNode;
  isBlur?: boolean;
  isFullWidth?: boolean; // fixed at the bottom as a Footer
  position?:
    | "bottom-left"
    | "bottom-right"
    | "bottom-center"
    | "top-left"
    | "top-right"
    | "top-center";
  sx?: SxProps;
}

const SectionFloatingForButtons = ({
  children,
  position = "bottom-center",
  isBlur,
  isFullWidth,
  sx,
}: SectionFloatingForButtonsProps) => {
  const [shadow, setShadow] = useState(
    position?.includes("bottom")
      ? window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight
      : window.scrollY > 0,
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const documentHeight = document.documentElement.scrollHeight;
      // Check if the user is at the bottom of the page
      if (position?.includes("bottom")) {
        // Hide box top shadow when scrolled to the bottom
        setShadow(scrollPosition < documentHeight);
      } else {
        // Hide box bottom shadow when scrolled to the top
        setShadow(window.scrollY !== 0);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getPositionStyles = () => {
    switch (position) {
      case "bottom-left":
        return { bottom: "48px", left: "264px" }; // next to Sidebar
      case "bottom-right":
        return { bottom: "48px", right: "56px" };
      case "bottom-center":
        return {
          bottom: "48px",
          left: "calc(50% + 120px)",
          transform: "translateX(-50%)",
        };
      case "top-left":
        return { top: "65px", left: "264px" };
      case "top-right":
        return { top: "65px", right: "56px" };
      case "top-center":
        return {
          top: "65px",
          left: "calc(50% + 120px)",
          transform: "translateX(-50%)",
        };
      default:
        return { bottom: "48px", left: "264px" };
    }
  };

  return (
    <>
      <Box sx={{ height: position?.includes("top") ? "65px" : "72px" }} />
      <Box
        sx={{
          position: "fixed",
          zIndex: 1000,
          padding: "var(--space-8px) var(--space-16px)",
          borderRadius: "8px",
          boxShadow:
            "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",

          ...getPositionStyles(),
          ...(isBlur
            ? {
                backdropFilter: "blur(10px)", // Apply the blur effect
                backgroundColor: "rgba(255, 255, 255, 0.6)", // Semi-transparent background
              }
            : { backgroundColor: "var(--white-primary)" }),

          ...(isFullWidth
            ? {
                padding: "var(--space-24px)",
                width: "calc(100% - 240px)",
                backgroundColor: "#F6F7FF",
                borderRadius: 0,
                ...(position?.includes("bottom")
                  ? { bottom: 0, paddingBottom: "var(--space-32px)" }
                  : {}),
                boxShadow: shadow
                  ? `0 ${position?.includes("top") ? "2px" : "-2px"} 10px rgba(0, 0, 0, 0.1)`
                  : "none",
              }
            : {}),
          ...sx,
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default SectionFloatingForButtons;
