import {
  getStructure,
  GetStructureExtraProps,
  ItemsTypes,
  toSubmitData,
} from "./ContractOfferStructure";
import {
  useCreateContractOfferCloneMutation,
  useCreateSubContractMutation,
  useCreateSubContractOfferMutation,
  useDeleteContractOfferMutation,
  useGetContractOfferTemplateDataQuery,
  useGetContractOfferQuery,
  usePatchContractOfferMutation,
} from "src/redux/services/ContractOfferService";
import { ContractOffer as ContractOfferType } from "src/accurasee-backend-types/app/contract_offer/contract_offer.types";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ActivityLogDialog from "src/components/Basic/Simple/Dialogs/ActivityLogDialog";
import ActivityLogs from "src/components/Complex/ActivityLogs/ActivityLogs";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import ButtonSecondary from "src/components/Basic/Simple/Buttons/ButtonSecondary";
import ButtonUpdate from "src/components/Basic/Simple/Buttons/ButtonUpdate";
import ContactPersonModal from "src/components/Complex/Modals/ContactPersonModal";
import CustomerModal from "src/components/Complex/Modals/customer/CustomerModal";
import MicrosoftDriveTable from "src/components/Basic/Mixed/Tables/MicrosoftDriveTable";
import NotFound from "src/components/Screens/NotFound/NotFound";
import Page from "src/components/Basic/Mixed/Pages/Page";
import React, { useState } from "react";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionFill from "src/components/Basic/Simple/Sections/SectionFill";
import SectionFloatingForButtons from "src/components/Basic/Simple/Sections/SectionFloatingForButtons";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import SendContractOfferModalWithForm from "./SendContractOfferModalWithForm";
import getContractOfferExternalId from "src/utils/getContractOfferExternalId";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useOnSubmit from "src/hooks/useOnSubmit";
import usePathPermission from "src/hooks/usePathPermission";
import useTranslation from "src/hooks/useTranslationWrapper";
import { CreateActivityLog } from "src/accurasee-backend-types/app/activity_log/activity_log.types";
import { Types } from "mongoose";
import { escape } from "src/utils/translate";
import { useCreateActivityLogMutation } from "src/redux/services/ActivityLogService";
import { useGetCompanyUsersQuery } from "src/redux/services/UserService";
import { useGetContactPeopleQuery } from "src/redux/services/ContactPersonService";
import { useGetContractTypesQuery } from "src/redux/services/ContractTypesServices";
import { useGetCurrenciesQuery } from "src/redux/services/CurrencyService";
import { useGetCustomFieldsQuery } from "src/redux/services/CustomFieldService";
import { useGetCustomersQuery } from "src/redux/services/CustomerService";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import PromptDialog from "../../../../components/Basic/Simple/Dialogs/PromptDialog";
import parseRestApiErrorMessage from "../../../../utils/parseRestApiErrorMessage";
import { useDispatch } from "react-redux";
import { backendApi } from "src/redux/api";
import CopyFilesCloneContractOfferModalWithForm from "./CopyFilesCloneContractOfferModalWithForm";
const ContractOffer = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const pathCreatePermissions = usePathPermission("/app/contracts/create");
  const { contractOfferId } = useParams<{ contractOfferId?: string }>();

  const pathPermissions = usePathPermission("/app/operations/customers");
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [isPromptProjectMissingOpen, setIsPromptProjectMissingOpen] =
    useState(false);
  const [isPromptATAExistsOpen, setIsPromptATAExistsOpen] = useState(false);
  const [openSendContractOfferModal, setOpenSendContractOfferModal] =
    useState(false);
  const [
    openCopyFilesCloneContractOfferModal,
    setOpenCopyFilesCloneContractOfferModal,
  ] = useState(false);
  const [contractOfferClone, setContractOfferClone] =
    useState<Partial<ContractOfferType>>();
  const [isSubmittingSubContract, setIsSubmittingSubContract] = useState(false);

  const [cloneContractOffer] = useCreateContractOfferCloneMutation();
  const [createActivityLog] = useCreateActivityLogMutation();
  const [createSubContract] = useCreateSubContractMutation();
  const [createSubContractOffer] = useCreateSubContractOfferMutation();
  const [deleteOffer] = useDeleteContractOfferMutation();
  const [patchOffer] = usePatchContractOfferMutation();

  const [isActivityLogDialogOpen, setIsActivityLogDialogOpen] = useState(false);

  const { data: customFieldsQuery, isLoading: isLoadingCustomFields } =
    useGetCustomFieldsQuery(undefined);
  const customFields =
    customFieldsQuery?.data?.filter(
      (customField) => customField.appliedToSections.includes("offer"), // custom fields for Contract Offer only
    ) || [];

  const { data: usersResponse, isLoading: isLoadingUsers } =
    useGetCompanyUsersQuery(undefined);
  const users = usersResponse?.data || [];

  const { data: contractTypesQuery, isLoading: isLoadingContractTypes } =
    useGetContractTypesQuery(undefined);
  const contractTypes = contractTypesQuery?.data || [];

  const { data: contactsRes, isLoading: isLoadingContacts } =
    useGetContactPeopleQuery(undefined);
  const contacts = contactsRes?.data || [];

  const { data: currenciesResponse, isLoading: isLoadingCurrencies } =
    useGetCurrenciesQuery(undefined);
  const currencies = currenciesResponse?.data || [];

  const { data: customersQuery, isLoading: isLoadingCustomers } =
    useGetCustomersQuery(undefined);

  const customers = customersQuery?.data || [];

  const {
    data: offer,
    isLoading,
    isError,
  } = useGetContractOfferQuery(contractOfferId, {
    skip: contractOfferId === undefined,
  });

  const {
    data: contractOfferTemplateData,
    isLoading: isLoadingContractOfferTemplateData,
  } = useGetContractOfferTemplateDataQuery(contractOfferId, {
    skip: contractOfferId === undefined,
  });

  const data = customFields.reduce<Record<string, any>>((acc, obj) => {
    acc[obj.name] = offer?.customFieldsData?.find(
      (i) => i.customFieldId === obj._id,
    )?.value;
    return acc;
  }, {});
  const initialFormData = { ...offer, ...data };

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<
    Partial<ContractOfferType>,
    GetStructureExtraProps,
    ItemsTypes
  >({
    getStructure,
    extraProps: {
      contacts,
      currencies,
      customers,
      customFields,
      grouping: offer?.grouping,
      users,
      contractTypes,
      isContactModalOpen,
      isCustomerModalOpen,
      setIsContactModalOpen,
      setIsCustomerModalOpen,
    },
    initialFormData,
  });

  const submitData = toSubmitData({
    data: formData,
    initData: initialFormData,
    customFields,
  });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: { update: patchOffer, delete: deleteOffer },
      data: { update: submitData, delete: [contractOfferId] },
      dataId: contractOfferId,
      name: "Offer",
      rerouteUrlOnSuccess: { delete: `/app/contract-offers/active` },
    },
    onSuccess: () => setHasTriedToSubmit(false),
  });

  const handleSave = (
    e: React.MouseEvent<HTMLButtonElement>,
    message: string,
  ) => {
    if (formData?.customerStatus && contractOfferId) {
      const activityLog: CreateActivityLog = {
        action: formData?.customerStatus,
        groupId: contractOfferId as unknown as Types.ObjectId,
        comment: message,
        emailRecipient: "",
        emailSender:
          contacts?.find((item) => item._id === formData?.contactPersonId)
            ?.email || "",
        type: "contract_offer",
      };

      onFormSubmit({ action: "update" })
        .then(() =>
          createActivityLog(activityLog)
            .then((res) => {
              if ("error" in res) {
                console.error(res.error);
                throw new Error("Failed");
              }
              enqueueSnackbar(t("Activity log created successfully"), {
                variant: "success",
              });
              setIsActivityLogDialogOpen(false);
            })
            .catch((err) => {
              enqueueSnackbar(t("Failed to create activity log"), {
                variant: "error",
              });
              console.log(err);
            }),
        )
        .catch((err) => console.log(err));
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsActivityLogDialogOpen(false);
  };

  const onSubmitCloneContractOffer = useOnSubmit({
    apiMutations: { create: cloneContractOffer },
    name: "clone contract offer",
  });

  const onSubmitCreateSubContract = useOnSubmit({
    apiMutations: { create: createSubContract },
    name: "sub contract",
  });

  const onSubmitCreateSubContractOffer = useOnSubmit({
    apiMutations: { create: createSubContractOffer },
    name: "sub contract offer",
    rerouteUrlOnSuccess: {
      create: ({ responseData }) =>
        `/app/contract-offers/overview/${responseData._id}`,
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  if (isError) {
    return <NotFound type="contract_offer_list" />;
  }

  return (
    <>
      <Page
        isLoading={
          isLoading ||
          isLoadingContacts ||
          isLoadingContractOfferTemplateData ||
          isLoadingContractTypes ||
          isLoadingCurrencies ||
          isLoadingCustomFields ||
          isLoadingCustomers ||
          isLoadingUsers
        }
        label={
          isLoading
            ? "Edit ... details"
            : offer?.category === "main"
              ? "Edit offer details"
              : "Edit sub offer details"
        }
        breadcrumbs={[
          {
            label: "Contract offers",
            link: "/app/contract-offers/" + offer?.groupingAll,
          },
          { label: `Offer${escape(` ${getContractOfferExternalId(offer)}`)}` },
          { label: "Overview" },
        ]}
        boxRight={
          <ButtonGroup>
            {offer?.familyId && (
              <Link
                component={RouterLink}
                to={`/app/contract-offers/${offer?.groupingAll}?familyId=${offer?.familyId}&contractOfferId=${offer?._id}`}
              >
                {t("Show all related offers")}
              </Link>
            )}
            <ButtonSecondary
              id="archive-button"
              key="archive-button"
              onClick={() => {
                if (offer?.hasBeenConverted || offer?.hasBeenDiscarded) {
                  enqueueSnackbar(
                    `${t(
                      offer?.hasBeenConverted && offer?.category === "main"
                        ? "Sorry this contract offer can not be made active since it already has been converted into a contract"
                        : offer?.hasBeenConverted && offer?.category === "sub"
                          ? "Sorry this sub contract offer can not be made active since it already has been converted into a sub contract"
                          : offer?.hasBeenDiscarded &&
                              offer?.category === "main"
                            ? "Sorry this contract offer can not be made active since another main contract offer with same project id has been converted into a contract"
                            : offer?.hasBeenDiscarded &&
                                offer?.category === "sub"
                              ? "Sorry this sub contract offer can not be made active since another main contract offer with same project id has been converted into a contract"
                              : "This should not happen",
                    )}`,
                    {
                      variant: "error",
                    },
                  );
                } else {
                  patchOffer({
                    id: contractOfferId as unknown as Types.ObjectId,
                    grouping:
                      offer?.grouping === "active" ? "archived" : "active",
                  })
                    .then(() =>
                      navigate(`/app/contract-offers/overview/${offer?._id}`),
                    )
                    .catch((e) => e);
                }
              }}
            >
              {t(
                offer?.grouping === "active"
                  ? "Archive offer"
                  : "Make offer active",
              )}
            </ButtonSecondary>
            {offer?.category === "main" && (
              <>
                <ButtonPrimary
                  disabled={offer?.grouping === "archived"}
                  onClick={() => {
                    if (offer?._id) {
                      onSubmitCloneContractOffer({
                        data: { create: String(offer?._id) },
                      })
                        .then(({ responseData }) => {
                          setContractOfferClone(responseData?.create);
                          setOpenCopyFilesCloneContractOfferModal(true);
                        })
                        .catch((e) => console.error(e));
                    }
                  }}
                  permissionType={"writeRights"}
                  permissions={pathCreatePermissions}
                >
                  {t("Duplicate")}
                </ButtonPrimary>
                <ButtonPrimary
                  key="subcontract-offer-button"
                  onClick={() => {
                    if (offer?._id) {
                      onSubmitCreateSubContractOffer({
                        data: { create: offer._id },
                      }).catch((e) => console.error(e));
                    }
                  }}
                >
                  {t("Create sub offer")}
                </ButtonPrimary>
              </>
            )}
            <ButtonPrimary
              disabled={
                offer?.customerStatus !== "approved" ||
                offer?.hasBeenConverted ||
                offer?.grouping === "archived"
              }
              isLoading={isSubmittingSubContract}
              onClick={() => {
                if (offer?.category === "main") {
                  navigate(`/app/contracts/create/${offer?._id}`);
                } else {
                  if (offer?._id) {
                    setIsSubmittingSubContract(true);
                    onSubmitCreateSubContract({
                      data: { create: offer._id },
                    })
                      .then((r) => {
                        // Manually invalidate the getActivityLogs query
                        dispatch(
                          backendApi.util.invalidateTags(["ActivityLogs"]),
                        );

                        setIsSubmittingSubContract(false);

                        if (
                          parseRestApiErrorMessage(r?.error?.create) ===
                          "Project not found 404"
                        ) {
                          setIsPromptProjectMissingOpen(true);
                        }
                        if (
                          parseRestApiErrorMessage(r?.error?.create) ===
                          "Already exists 400"
                        ) {
                          setIsPromptATAExistsOpen(true);
                        }
                      })
                      .catch((e) => {
                        setIsSubmittingSubContract(false);
                        console.log(e);
                      });
                  }
                }
              }}
              permissionType={"writeRights"}
              permissions={pathCreatePermissions}
            >
              {t(
                offer?.category === "main"
                  ? "Create contract"
                  : "Create sub contract",
              )}
            </ButtonPrimary>
          </ButtonGroup>
        }
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (submitData?.customerStatus) {
              setIsActivityLogDialogOpen(true);
            } else {
              onFormSubmit({ action: "update" }).catch((err) =>
                console.log(err),
              );
            }
          }}
          ref={refForm}
        >
          <SectionContainer>
            <Section key={"offer-info"} label={"Offer info"} md={12} lg={4}>
              {BuildItems({
                items: structure.items.filter(
                  (item) => item.itemType === "info",
                ),
                ...commonPropsBuildItem,
              })}
            </Section>
            <SectionFill
              id={"offer-detail"}
              key={"offer-detail"}
              label={"Details"}
              md={12}
              lg={4}
            >
              {BuildItems({
                items: structure.items.filter(
                  (item) => item.itemType === "details",
                ),
                ...commonPropsBuildItem,
              })}
            </SectionFill>
            <SectionFill
              id={"offer-customer"}
              key={"offer-customer"}
              label={"Customer"}
              md={12}
              lg={4}
            >
              {BuildItems({
                items: structure.items.filter(
                  (item) => item.itemType === "customer",
                ),
                ...commonPropsBuildItem,
              })}
            </SectionFill>

            <MicrosoftDriveTable
              activityLogGroupId={contractOfferId as unknown as Types.ObjectId}
              collectionName={"contract_offers"}
              templateData={contractOfferTemplateData}
              leafFolder={getContractOfferExternalId(formData, true)}
              prefixDocumentName={getContractOfferExternalId(formData)}
              showTemplateButton
            />

            <ActivityLogs
              groupId={contractOfferId as unknown as Types.ObjectId}
              label={"communications"}
              type={"contract_offer"}
            />

            <ActivityLogs
              groupId={contractOfferId as unknown as Types.ObjectId}
              label={"document activity log"}
              type={"microsoft_drive_file"}
            />
          </SectionContainer>
        </form>

        {/* Floating Button Wrapper as a Footer*/}
        <SectionFloatingForButtons isFullWidth>
          <SectionWithButtonContainer sx={{ margin: 0 }}>
            <ButtonPrimary
              // Needs to update changed data to enable Send offer
              disabled={
                Object.keys(submitData || {}).length > 0 ||
                offer?.grouping === "archived"
              }
              key="send-offer-button"
              onClick={() => setOpenSendContractOfferModal(true)}
            >
              {t("Send offer")}
            </ButtonPrimary>
            <ButtonGroup>
              <ButtonClear
                onClick={() => {
                  setFormData(initialFormData);
                }}
              />
              <ButtonUpdate
                id={"edit-offer"}
                initialSubmitData={{}}
                isValid={isFormValid}
                isSubmitting={isSubmitting}
                onSubmit={() => {
                  refForm.current.requestSubmit();
                  setHasTriedToSubmit(true);
                }}
                submitData={submitData}
              />
            </ButtonGroup>
          </SectionWithButtonContainer>
        </SectionFloatingForButtons>
      </Page>

      {/* Add Customer Modal */}
      <CustomerModal
        openModal={isCustomerModalOpen && !!pathPermissions?.writeRights}
        setOpenModal={setIsCustomerModalOpen}
        postCreate={(resData) => {
          // To show Customer info to the form
          if (resData._id !== undefined && formData) {
            const state: Partial<ContractOfferType> = {
              ...formData,
              customerId: resData._id,
            };
            setFormData(state);
          }
        }}
      />

      {/* Add Contact Modal */}
      <ContactPersonModal
        customerId={formData?.customerId}
        openModal={isContactModalOpen && !!pathPermissions?.writeRights}
        setOpenModal={setIsContactModalOpen}
        postCreate={(resData) => {
          // To show Customer info to the form
          if (!!resData && formData) {
            const state = {
              ...formData,
              contactPersonId: resData._id,
            };
            setFormData(state);
          }
        }}
      />

      {/* Send offer */}
      {openSendContractOfferModal && (
        <SendContractOfferModalWithForm
          contractOffer={formData}
          emailRecipient={
            contacts?.find((item) => item._id === formData?.contactPersonId)
              ?.email
          }
          openModal={openSendContractOfferModal}
          onClose={() => setOpenSendContractOfferModal(false)}
        />
      )}

      <CopyFilesCloneContractOfferModalWithForm
        openModal={openCopyFilesCloneContractOfferModal}
        onClose={() => {
          setOpenCopyFilesCloneContractOfferModal(false);
          if (contractOfferClone) {
            navigate(`/app/contract-offers/overview/${contractOfferClone._id}`);
          }
        }}
        contractOffer={formData}
        contractOfferClone={contractOfferClone}
      />

      <ActivityLogDialog
        message={`Please add a comment describing why status was changed`}
        open={isActivityLogDialogOpen}
        onSave={handleSave}
        onCancel={handleCancel}
      />

      <PromptDialog
        title={"Project missing in byggkollen"}
        message={
          "Please make sure project has been created in byggkollen before creating an ÄTA"
        }
        open={isPromptProjectMissingOpen}
        onClose={() => setIsPromptProjectMissingOpen(false)}
      />

      <PromptDialog
        title={"Sub contract already exists"}
        message={
          "Subcontract already exists for this contract offer in byggkollen. This information has know been synced to accurasee"
        }
        open={isPromptATAExistsOpen}
        onClose={() => setIsPromptATAExistsOpen(false)}
      />
    </>
  );
};
export default ContractOffer;
